<template>
  <div>
    <article>
      <h3 class="left">快音聚合广告SDK合规指南</h3>
      <section>
        <p>
          根据《个人信息保护法》、《数据安全法》、《网络安全法》等法律法规和监管部门规 章要求 ，App开发运营者（
          以下简称为“开发者）在提供网络产品服务时应尊重和保护最终用户的个人信息，不得违法违规收集使用个人信息，保证和承诺就个人信息处理行为获得最终用户的授权同意，遵循最小必要原则，且应当采取有效的技术措施和组织措施确保个人信息安全。为帮助开发者在使用快音聚合广告SDK（以下统称为“广告SDK”）的过程中更好地落实用户个⼈信息保护相关要求，避免出现侵害最终用户个⼈信息权益的情形，特制定本合规使用说明，供开发者在接入使用快音聚合广告SDK服务时参照自查和合理配置，不断提升个人信息保护水平。，我们需要收集您的必要个人信息。具体包括：
        </p>
      </section>
      <section>
        <h5>一 、升级到满足监管新规的最新版本</h5>
        <p>针对快音聚合广告SDK版本升级 ，我们会在第一时间告知并在接入文档中更新。</p>
      </section>
      <section>
        <h5>二 、SDK隐私政策披露要求与示例</h5>
        <p>
          接入说明：开发者在App集成广告SDK后，广告SDK的正常运行会收集必要的最终用户信息用于展示内容及向最终用户推荐可能感兴趣的内容。请开发者根据集成广告SDK的实际情况，在您App的隐私政策中，对广告SDK名称、公司名称、处理个⼈信息种类及目的、采集方式、隐私政策链接等内容进行披露。建议：确认您所接⼊的广告SDK版本和功能模块；根据上述版本和模块，从隐私政策中确定与广告SDK交互的数据内容；在您App的隐私政策中，以文字或列表的方式向公众披露广告SDK的相关信息。SDK披露信息具体见
          <a href="https://landing.kaixinyf.cn/sdk-privacy-protocol" target="_blank">《快音聚合广告SDK隐私政策》</a>
        </p>
      </section>
      <section>
        <h5>三 、SDK初始化及业务功能调用时机</h5>
        <p>
          请务必在用户同意您App中的隐私政策后，再进行广告SDK的初始化。用户同意隐私政策之前，避免动态申请涉及用户个⼈信息的敏感设备权限；用户同意隐私政策前，您应避免私自采集和上报个人信息。当您的App未向用户提供服务时，例如App在后台运行时，请勿请求广告SDK的相关服务。
        </p>
        <p>具体的初始化时机参考示例。</p>
        <p>本方案支持快音广告SDK6.27.01及更高版本，强烈建议开发者将快音广告SDK升级到6.27.01 版本。</p>
        <p>初始化步骤</p>
        <p>
          【1】 确保App首次冷启动时 ，在用户阅读您的《隐私政策》并取得用户授权之后
          ，才调用初始化函数CombineAdSdk.getInstance().initialize()初始化广告SDK，反之，如果用户不同意《隐私政策》授权
          ，则不能调调用CombineAdSdk.getInstance().initialize()初始化函数。
        </p>
        <p class="code indent-1">public class XXXApplication : Application{</p>
        <p class="code indent-2">override fun onCreate() {</p>
        <p class="code indent-3">super.onCreate()</p>
        <p class="code indent-3">// 同意隐私政策后调用</p>
        <p class="code indent-3">initSdk()</p>
        <p class="code indent-2">}</p>
        <p></p>
        <p class="code indent-2">private fun initSdk() {</p>
        <p class="code indent-3">val combineAdsdk = CombineAdSdk.getInstance()</p>
        <p class="code indent-3">combineAdsdk.initialize(</p>
        <p class="code indent-4">this, AdConfig.Builder()</p>
        <p class="code indent-5">.setAppId(KY_AD_SDK_APP_ID)</p>
        <p class="code indent-5">.setGdt(TENCEN_AD_APP_ID)</p>
        <p class="code indent-5">.setTT(TT_APP_ID)</p>
        <p class="code indent-5">.setKs(KS_APP_ID)</p>
        <p class="code indent-5">.setBd(BD_APP_ID)</p>
        <p class="code indent-5">.setWxAppId("wxappid") // 某些广告支持小程序跳转需要设置微信appid</p>
        <p class="code indent-5">.setDebuggable(true)</p>
        <p class="code indent-5">.build()</p>
        <p class="code indent-3">) { // 初始化成功</p>
        <p class="code indent-4">combineAdsdk.setUp(this, "10.10.0", "快音demo")</p>
        <p class="code indent-3">}</p>
        <p class="code indent-2">}</p>
        <p class="code indent-1">}</p>
        <p>
          【2】 ⼀旦App获取到《隐私政策》 的用户授权，后续的App冷启动，开发者应该保证在
          Applicaiton.onCreate函数中调用始化函数CombineAdSdk.getInstance().initialize()。
        </p>
      </section>

      <section>
        <h5>四、SDK可选个⼈信息配置说明</h5>
        <p>相关信息的不收集会对其对应的功能造成影响，请开发者结合业务实际需要进行合理配置。API说明（KyPrivacyController）</p>
        <div class="table">
          <div class="t-head">
            <ul>
              <li v-for="(item, index) in option.column" :key="index">{{ item.label }}</li>
            </ul>
          </div>
          <div class="t-body" v-for="(item, index) in tableData" :key="index">
            <ul>
              <li v-for="(context, i) in option.column" :key="i">
                <span v-if="context.prop !== 'Links'">{{ item[context.prop] }}</span>
                <span v-else class="align-start"><a :href="item[context.prop]"
                    v-if="typeof item[context.prop] === 'string'">{{
                      item[context.prop]
                    }}</a>
                  <a :href="link" v-for="(link, index) in item[context.prop]" v-show="Array.isArray(item[context.prop])"
                    :key="index">{{ link }} <br /></a>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <p>接⼊示例</p>
        <p>在SDK初始化阶段（ 即在CombineAdSdk.getInstance().initialize()处）传⼊ </p>
        <p class="code indent-1">/*** 用户信息采集配置*/</p>
        <p class="code indent-1">CombineAdSdk.getInstance().privacyController = object : KyPrivacyController() {</p>
        <p class="code indent-2">override fun isCanGetAppList(): Boolean {</p>
        <p class="code indent-3">return super.isCanGetAppList()</p>
        <p class="code indent-2">}</p>
        <p class="code indent-2">override fun isCanUseAndroidId(): Boolean {</p>
        <p class="code indent-3">return super.isCanUseAndroidId()</p>
        <p class="code indent-2">}</p>
        <p class="code indent-2">override fun isCanUseLocation(): Boolean {</p>
        <p class="code indent-3">return super.isCanUseLocation()</p>
        <p class="code indent-2">}</p>
        <p class="code indent-2">override fun isCanUseOaid(): Boolean {</p>
        <p class="code indent-3">return super.isCanUseOaid()</p>
        <p class="code indent-2">}</p>
        <p class="code indent-2">override fun isCanUsePhoneState(): Boolean {</p>
        <p class="code indent-3">return super.isCanUsePhoneState()</p>
        <p class="code indent-2">}</p>
        <p class="code indent-2">override fun isCanUseWifiState(): Boolean {</p>
        <p class="code indent-3">return super.isCanUseWifiState()</p>
        <p class="code indent-2">}</p>
        <p class="code indent-1">}</p>
      </section>

      <section>
        <h5>五 、SDK申请系统权限的说明</h5>
        <p>要求内容：《SDK合规使用说明》应详细说明SDK所需的系统权限与各业务功能间的关系， 并说明权限申请时机。</p>
        <p>接入说明：对于广告SDK可选申请的系统权限，您可以参考相关如下表格的内容，详细了解相关权限与各 业务功能的关系及其申请时机，因相关权限的不申请将会对其对应的功能造成影响，您可以结合业务实际需要进行合理配置。</p>
        <p>安卓操作系统应用权限列表：</p>
        <div class="table">
          <div class="t-head">
            <ul>
              <li v-for="(item, index) in option1.column" :key="index">{{ item.label }}</li>
            </ul>
          </div>
          <div class="t-body" v-for="(item, index) in tableData1" :key="index">
            <ul>
              <li v-for="(context, i) in option1.column" :key="i">
                <span>{{ item[context.prop] }}</span>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section>
        <h5>六 、SDK扩展功能配置</h5>
        <p>快音聚合广告SDK向您提供了扩展功能的控制开关，您可以根据APP所需的SDK功能服务自行配置打开或关闭。请您注意，SDK不强制App使用该扩展功能，即使没有获取该功能，SDK提供的基本功能也能正常运行。</p>
        <p>1、唤起行为能力，用于设置应用安装完成后是否显示打开应用的提示弹窗：</p>
        <ul>
          <li>
            （1）、功能说明：设置转化优化信息（仅支持优量汇）
          </li>
          <li>（2）、接口名称：setExtrasConfig</li>
          <li>
            （3）、调用方法：
            <p class="code indent-1">// 不设置或设置为true都显示打开应用弹窗，设置为false不显示弹窗，也不会自动打开应用</p>
            <p class="code indent-1">val extrasConfig = mapOf(Pair(ExtraConfigConstant.APP_CONVERT, false))</p>
            <p class="code indent-1">CombineAdSdk.getInstance().setExtrasConfig(extrasConfig)</p>
          </li>
        </ul>
        <p>2、快音聚合广告SDK<span
            class="bold">提供个性化广告关闭功能</span>，为开发者提供退出个性化广告能力的接口，开发者可以调用接口，向最终用户提供退出个性化广告的能力。退出后，最终用户看到的广告数量不变，相关度会降低。开发者需遵守相关法律法规的要求，在
          App 内为最终用户提供退出个性化广告的功能，保证在最终用户点击退出功能后调用快音聚合广告SDK的能力接口。</p>
        <ul>
          <li>（1）、功能说明：个性化广告关闭功能</li>
          <li>（2）、接口名称：setExtrasConfig</li>
          <li>（3）、调用方法：</li>
          <p class="code indent-1">// 设置成false则关闭个性化推荐</p>
          <p class="code indent-1">val extrasConfig = mapOf(Pair(ExtraConfigConstant.APP_PERSONAL_RECOMMEND, false))</p>
          <p class="code indent-1">CombineAdSdk.getInstance().setExtrasConfig(extrasConfig)</p>
        </ul>
      </section>

      <section>
        <h5>七、最终用户行使权利的配置说明</h5>
        <p>接入说明：开发者在其App中集成广告SDK后 ，广告SDK的正常运行会收集必要的最终用户信息用于实现业务功能
          。开发者应根据相关法律法规为最终用户提供行使个⼈信息主体权利的路径或功能，需要广告SDK配合的，请与广告SDK及时进行联系，我们将与开发者协同妥善解决最终用户的诉求。</p>
      </section>
    </article>
  </div>
</template>

<script>
export default {
  name: 'UserReleaseCommitment',
  components: {},
  props: {},
  data() {
    return {
      option: {
        column: [
          {
            label: '接口',
            prop: 'interface',
          },
          {
            label: '描述',
            prop: 'description',
          },
          {
            label: '备注',
            prop: 'remarks',
          },
        ],
      },
      tableData: [
        {
          interface: 'isCanUsePhoneState',
          description: '设备标识符（ 如： MAC地址、OAID、IMSI、IMEI，具体字段因软硬件版本不同而存在差异）',
          remarks: "是否允许SDK获取设备ID，用于广告投放 、反作弊追踪"
        },
        {
          interface: "isCanUseOaid",
          description: "设备标识符-OAID（必要信息）",
          remarks: "Android10+采用oaid追踪用户的广告行为；请传入原始oaid，无需使用 md5加密，共享信息给SDK"
        },
        {
          interface: "isCanUseAndroidId",
          description: "设备标识符-AndroidID（必要信息）",
          remarks: "AndroidID，辅助用户行为分析"
        },
        {
          interface: "isCanUseWifiState",
          description: "网络状态（WiFi状态）",
          remarks: "共享WiFi状态给SDK"
        },
        {
          interface: "isCanGetAppList",
          description: "应用安装列表",
          remarks: "是否允许读取应用安装列表，用于判断用户已安装应用，优化广告投放、下载、安装逻辑"
        },
        {
          interface: "isCanUseLocation",
          description: "位置信息",
          remarks: "共享位置信息给SDK"
        },
      ],
      option1: {
        column: [
          {
            label: '权限',
            prop: 'permission',
          },
          {
            label: '功能',
            prop: 'function',
          },
          {
            label: '用途和目的',
            prop: 'uses',
          },
          {
            label: '申请时机',
            prop: 'timing',
          },
        ],
      },
      tableData1: [
        {
          permission: "READ_PHONE_STATE",
          function: "【可选】读取手机设备 标识(设备 IMSI/IMEI号）等信息",
          uses: "根据设备信息情况实现流量分组功能",
          timing: "开发者在调用需要该权 限的SDK功能时进⾏调用。例如当开发者需要根据设备信息情况使用调整广告变现策略及数据分析服务时进行时申请。"
        },
        {
          permission: "QUERY_ALL_PACKAG ES应用软件列表",
          function: "【可选】获取应用软件列表",
          uses: "由其他广告平台调取，用于广告投放",
          timing: "开发者在调用需要该权 限的SDK功能时进行调用。例如当开发者投放其他广告平台的广告，其他广告平台需要依据应用软件列表实现广告 投放时进行申请。"
        }
      ]
    };
  },
  computed: {},
  watch: {},
  created() { },
  mounted() {
    document.title = '快音聚合广告SDK合规指南';
  },
  methods: {},
};
</script>

<style scoped>
body {
  font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
    sans-serif;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #fff;
  padding-left: constant(safe-area-inset-left);
  padding-left: env(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  padding-right: env(safe-area-inset-right);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

article {
  margin: 0 4%;
}

h3 {
  margin: 15px 0;
  padding-top: 0;
  padding-bottom: 0;
  color: #3c3c3c;
  font-size: 18px;
}

h5 {
  margin: 15px 0;
  font-size: 14px;
}

p {
  margin: 12px 0;
  font-size: 13px;
  text-indent: 2em;
}

.code {
  margin: 4px 0;
  font-size: 14px;
}

.indent-1 {
  text-indent: 0em;
}

.indent-2 {
  text-indent: 2em;
}

.indent-3 {
  text-indent: 4em;
}

.indent-4 {
  text-indent: 6em;
}

.indent-5 {
  text-indent: 8em;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.sign {
  margin: 50px 0;
  text-align: right;
}

a {
  color: #007aff;
  text-decoration: underline;
}

ul>li {
  margin: 5px 0;
  font-size: 13px;
  list-style: none;
}

ul>li>span {
  display: inline-block;
  margin: 4px 0;
}

ul>li>span.bold {
  display: inline;
}

i {
  font-style: italic;
}

.table {
  margin: 15px 0;
  border: 1px solid #aaa;
  width: 100%;
  border-bottom: 0;
}

.t-head {
  background: #c6c6c6 no-repeat scroll 0 0;
}

.t-head ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.t-head ul li {
  width: 30%;
  text-align: center;
  font-weight: bold;
}

.t-body {
  background-color: #fff;
}

.t-body ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid #aaaaaa;
}

.t-body ul li {
  position: relative;
  width: 33%;
  padding: 4px;
  text-align: center;
  word-break: break-all;
}

a {
  color: #007aff;
  text-decoration: underline;
}
</style>
